var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("a-modal", {
    attrs: {
      title: "配置",
      width: 500,
      footer: null,
      centered: ""
    },
    model: {
      value: _vm.visible2,
      callback: function callback($$v) {
        _vm.visible2 = $$v;
      },
      expression: "visible2"
    }
  }, [_c("a-spin", {
    attrs: {
      spinning: _vm.spinning
    }
  }, [_c("a-form-model", {
    ref: "form1",
    staticClass: "form1",
    attrs: {
      model: _vm.form1,
      rules: _vm.rules1,
      labelCol: {
        span: 4
      },
      wrapperCol: {
        span: 20
      }
    }
  }, [_vm._l(_vm.form1.classifyList, function (item, index) {
    return _c("a-form-model-item", {
      key: item.id,
      attrs: {
        label: index + 1,
        prop: "classifyList." + index + ".categoryname",
        rules: _vm.rules1.categoryname
      }
    }, [_c("a-input", {
      staticStyle: {
        width: "60%",
        "margin-right": "8px"
      },
      attrs: {
        placeholder: "请输入"
      },
      model: {
        value: item.categoryname,
        callback: function callback($$v) {
          _vm.$set(item, "categoryname", $$v);
        },
        expression: "item.categoryname"
      }
    }), _c("a-icon", {
      attrs: {
        type: "minus-circle"
      },
      on: {
        click: function click($event) {
          return _vm.handleDeleteCategory(item, index);
        }
      }
    })], 1);
  }), _c("a-button", {
    staticClass: "add-btn",
    on: {
      click: _vm.handleAdd
    }
  }, [_vm._v("新 增")])], 2), _c("a-button", {
    staticClass: "submit-btn",
    attrs: {
      loading: _vm.loading,
      type: "primary"
    },
    on: {
      click: _vm.handleSubmit1
    }
  }, [_vm._v("提 交")])], 1)], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };