import "core-js/modules/es.function.name.js";
import "core-js/modules/es.array.sort.js";
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("a-modal", {
    attrs: {
      title: _vm.isEdit ? "编辑" : "创建",
      width: 500,
      footer: null,
      centered: ""
    },
    model: {
      value: _vm.visible,
      callback: function callback($$v) {
        _vm.visible = $$v;
      },
      expression: "visible"
    }
  }, [_c("a-form-model", {
    ref: "ruleForm",
    attrs: {
      model: _vm.form,
      rules: _vm.rules,
      labelCol: {
        span: 6
      },
      wrapperCol: {
        span: 14
      }
    }
  }, [_c("a-form-model-item", {
    attrs: {
      label: "合作方名称",
      prop: "name"
    }
  }, [_c("a-input", {
    attrs: {
      placeholder: "请输入合作方名称"
    },
    model: {
      value: _vm.form.name,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "name", $$v);
      },
      expression: "form.name"
    }
  })], 1), _c("a-form-model-item", {
    attrs: {
      label: "合作方简介",
      prop: "desc"
    }
  }, [_c("a-input", {
    attrs: {
      placeholder: "请输入合作方简介"
    },
    model: {
      value: _vm.form.desc,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "desc", $$v);
      },
      expression: "form.desc"
    }
  })], 1), _c("a-form-model-item", {
    attrs: {
      label: "合作方分类",
      prop: "categoryId"
    }
  }, [_c("a-select", {
    attrs: {
      placeholder: "请选择合作方分类"
    },
    on: {
      change: _vm.handleSelectChange
    },
    model: {
      value: _vm.form.categoryId,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "categoryId", $$v);
      },
      expression: "form.categoryId"
    }
  }, _vm._l(_vm.classifyList, function (item) {
    return _c("a-select-option", {
      key: item.id,
      attrs: {
        value: item.id
      }
    }, [_vm._v(" " + _vm._s(item.categoryname) + " ")]);
  }), 1)], 1), _c("a-form-model-item", {
    attrs: {
      label: "合作方链接",
      prop: "url"
    }
  }, [_c("a-input", {
    attrs: {
      placeholder: "请输入合作方链接"
    },
    model: {
      value: _vm.form.url,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "url", $$v);
      },
      expression: "form.url"
    }
  })], 1), _c("a-form-model-item", {
    attrs: {
      label: "排序",
      prop: "sort"
    }
  }, [_c("a-input-number", {
    staticStyle: {
      width: "100%"
    },
    attrs: {
      placeholder: "请输入排序"
    },
    model: {
      value: _vm.form.sort,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "sort", $$v);
      },
      expression: "form.sort"
    }
  })], 1), _c("a-form-model-item", {
    attrs: {
      label: "ICON",
      prop: "icon"
    }
  }, [_c("div", {
    staticStyle: {
      display: "flex",
      "align-items": "center"
    }
  }, [_c("a-upload", {
    staticClass: "avatar-uploader",
    attrs: {
      "list-type": "picture-card",
      "show-upload-list": false,
      accept: "image/*",
      action: "",
      customRequest: _vm.customRequest,
      "before-upload": _vm.beforeUpload
    }
  }, [_vm.form.icon && !_vm.loading2 ? _c("img", {
    staticStyle: {
      width: "100%",
      height: "100%"
    },
    attrs: {
      src: _vm.form.icon
    }
  }) : _c("div", [_c("a-icon", {
    attrs: {
      type: _vm.loading2 ? "loading" : "plus"
    }
  })], 1)]), _c("span", {
    staticStyle: {
      "line-height": "1.5",
      width: "100%"
    }
  }, [_vm._v("建议尺寸：64*64 "), _c("br"), _vm._v(" 建议大小：100KB")])], 1)]), _c("a-form-model-item", {
    attrs: {
      label: "状态",
      prop: "status"
    }
  }, [_c("a-radio-group", {
    model: {
      value: _vm.form.status,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "status", $$v);
      },
      expression: "form.status"
    }
  }, [_c("a-radio", {
    attrs: {
      value: 0
    }
  }, [_vm._v("待发布")]), _c("a-radio", {
    attrs: {
      value: 1
    }
  }, [_vm._v("已发布")])], 1)], 1), _c("a-form-model-item", {
    attrs: {
      label: " "
    }
  }, [_c("div", {
    staticStyle: {
      display: "flex",
      gap: "10px"
    }
  }, [_c("a-button", {
    attrs: {
      type: "primary",
      loading: _vm.confirmLoading
    },
    on: {
      click: _vm.handleSubmit
    }
  }, [_vm._v("发 布")]), _c("a-button", {
    on: {
      click: _vm.handleCancel
    }
  }, [_vm._v("取 消")])], 1)])], 1)], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };