var searchKeys = [{
  label: '合作方名称',
  key: "name",
  placeholder: "合作方名称",
  required: false,
  rules: [],
  input: true
}, {
  label: '跳转链接',
  key: "url",
  placeholder: "跳转链接",
  required: false,
  rules: [],
  input: true
}, {
  key: "button",
  button: true
}];
export { searchKeys };