var columns = [{
  title: "ID",
  dataIndex: "id",
  key: "id"
}, {
  title: "排序值",
  dataIndex: "sort",
  key: "sort"
}, {
  title: "icon",
  dataIndex: "icon",
  key: "icon",
  scopedSlots: {
    customRender: "icon"
  }
}, {
  title: "合作方名称",
  dataIndex: "name",
  key: "name"
}, {
  title: "合作方简介",
  dataIndex: "desc",
  key: "desc"
}, {
  title: "合作方分类",
  dataIndex: "categoryname",
  key: "categoryname"
}, {
  title: "跳转链接",
  dataIndex: "url",
  key: "url",
  width: 200,
  slots: {
    title: "url"
  },
  scopedSlots: {
    customRender: "url"
  }
}, {
  title: "状态",
  dataIndex: "status",
  key: "status",
  slots: {
    title: "status"
  },
  scopedSlots: {
    customRender: "status"
  }
}, {
  title: "操作",
  key: "action",
  textAlign: "center",
  fixed: "right",
  width: 140,
  scopedSlots: {
    customRender: "action"
  }
}];
export { columns };